/* .info-bar{

    position: fixed;

    bottom: 0;
    height: 40px;
    width: 90%;
    z-index: 10;
    justify-content: space-between;
} */

.info-bar-gp{
    /* width: 70%; */
}

.info-total{
    margin-left: 5px;
}

.info-qty{
    margin: 10px;
    height: 25px;
    width: 25px;
    font-size: 0.75rem;
    border-radius: 30px;
}
.info-cart-btn{
    width: 30%;
}