@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
*{
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    font-family: "Noto Sans TC", "Lato", sans-serif;
    /* font-weight: 500!important; */
}
/* chinese */
@font-face {
    font-family: "Noto Sans TC", sans-serif;
    src: url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
    unicode-range: U+4E00-9FFF;
}
/* english */
@font-face{
    font-family: 'Lato', sans-serif;
    src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
    unicode-range: U+00-024F;
}
/* *{

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
} */

*::-webkit-scrollbar {
    display: none;
}
*{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
input[type="radio"] {
    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
    -moz-appearance: checkbox;    /* Firefox */
    -ms-appearance: checkbox;     /* not currently supported */
}
input[type='text'],
textarea{
    font-size: 16px;
}
body{
    color:  #373d4a;
}
input, .StripeElement{
    max-width: unset !important;
}

:root{
    --head-bar-height: 60px;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-left-color: #009688;
    border-radius: 30px; /* border-radius: 50% */
    -webkit-animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
    animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
}

@-webkit-keyframes animation-rotate {
    100% {
    -webkit-transform: rotate(360deg);
    }
}
@keyframes animation-rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

.mat-button{
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: .25px;
    min-width: 60px;
    min-height: 40px;
    display: grid;
    place-items: center;
    color: rgba(0,0,0,.54);
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.mat-button:hover{
    background: rgba(0,0,0,.06);
}
.mat-button:active{
    background: rgba(0,0,0,.12);
}
.mat-warn{
    background-color: #d32f2f;
    color: white;
}
.mat-button-disable{
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: .25px;
    min-width: 60px;
    min-height: 40px;
    display: grid;
    place-items: center;
    background-color: rgba(0,0,0,.06);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgba(0,0,0,.26);
    font-size: 0.9rem;
    font-weight: 500;
}
.mat-disable{
    background-color: rgba(0,0,0,.06);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgba(0,0,0,.26);
}
.mat-primary{
    background-color: #1a73e8;
    color: white;
}
.mat-primary:hover{
    background-color: #1765cc;
    color: white;
}
.mat-primary:active{
    background-color: #1457b0;
    color: white;
}
.mat-warn:hover{
    background-color: #ba2929;
}
.mat-warn:active{
    background-color: #a02424;
}
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
.modal-content {
    /* background-color: #fefefe; */
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    /* border: 1px solid #888; */
    width: 80%;
  }
.dialog-backdrop {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.dialog-container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    margin-top: 30%;
    /* left: 25%;
    top: 25%; */
    top:0;
    left: 0;
    right: 0;
    margin-left: auto; 
    margin-right: auto; 
    /* width: 300px; */
    max-width: 600px;
    overflow: auto; /* Enable scroll if needed */
  }
  @media screen and (max-width: 960px) {
    .dialog-container{
      max-width: 600px;
    }
  } 
  @media screen and (max-width: 633px) {
    .dialog-container{
      max-width: 500px;
    }
  }
  @media screen and (max-width: 575px) {
    .dialog-container{
      max-width: 90%;
    }
  } 
.dialog {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
.dialog-head{
    padding: 1.3rem;
    width: 100%;
    height: 100%;
}
.dialog-warning{
    font-weight: 500;
    color: #c62828;
    background: #fbe9e7;
}

.dialog-warning :is(h6,h5){
    font-weight: 500;
    color: #c62828;
    background: #fbe9e7;
}
.dialog-warning h6{
    font-size: 0.9rem;
}

.dialog-content{
    padding: 1.3rem;
}

.dialog-content label{
    color: rgba(0,0,0,.54);
    font-size: 0.85rem;
}
.error-text{
    color: #c62828;
    font-size: 0.75rem;
    line-height: 1rem;
    position: relative;
}
.error-text i{
    font-size: 0.95rem;
}
/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.modal-head .head-btn{
    border-radius: 50%;
    height: 40px;
    width: 40px;
}
.center, .head-btn, .btn{
    display: grid;
    place-items: center;
}
.touch, .head-btn, .btn\:circle, .btn{
    cursor: pointer;
}
.untouch{
    cursor: unset;
}
.shadow{
    box-shadow: 0 8px 10px rgb(0 0 0 / 1%), 0 3px 7px -1px rgb(0 0 0 / 15%);
}
.invisible{
    visibility: hidden;
}
.shadow\:light{
    -webkit-box-shadow: 15px 15px 35px -8px rgba(81,81,81,0.53); 
    box-shadow: 15px 15px 35px -8px rgba(81,81,81,0.53);
}
.head-btn, .btn{
    min-height: 50px;
    min-width: 50px;
    font-size: 1.25rem;
}
.head-bar{
    height: var(--head-bar-height);
    width: 100%;
}
.btn\:circle{
    border-radius: 50%;
    font-size: 1.25rem;
}

.border\:left, .border\:all{
    border-left: 1px solid rgba(0,0,0,.2);
}
.border\:right, .border\:all{
    border-right: 1px solid rgba(0,0,0,.2);
}
.border\:top, .border\:all{
    border-top: 1px solid rgba(0,0,0,.2);
}
.border\:bottom, .border\:all{
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.c\:row{
    display: flex;
    flex-direction: column;
}
.c\:between{
    justify-content:space-between;
}
.c\:evenly{
    justify-content:space-evenly;
}
.c\:around{
    justify-content: space-around;
}
.c\:col{
    display: flex;
}
.c\:center{
    justify-content: center;
}
.c\:left{
    justify-content: flex-start;
}
.c\:right{
    justify-content: flex-end;
}
.justify-content-start{
    justify-content: flex-start;
}
.justify-content-between{
    justify-content: space-between;
}
.justify-content-around{
    justify-content: space-around;
}
.justify-content-end{
    justify-content: flex-end;
}
.justify-content-center{
    justify-content: center;
}
.justify-self-start{
    justify-self: flex-start;
}
.justify-self-center{
    justify-self: center;
}
.justify-self-end{
    justify-self: flex-end;
}
.align-content-between{
    align-content: space-between;
}
.align-items-start{
    align-items: flex-start;
}
.align-items-end{
    align-items: flex-end;
}
.align-items-center{
    align-items: center;
}
.align-self-start{
    align-self: flex-start;
}
.align-self-end{
    align-self: flex-end;
}
.align-self-center{
    align-self: center;
}

.c\:middle{
    align-items: center;
}
.flex\:1{
    flex: 1;
}
.flex\:3{
    flex: 3;
}
.float\:left{
    float: left;
}
.float\:right{
    float: right;
}


.gap\:xs{
    gap: .375rem;
}
.gap\:sm{
    gap: .75rem;
}
.gap\:md{
    gap: 1rem;
}
.gap\:lg{
    gap: 1.25rem;
}

.mb\:xs, .my\:xs, .m\:xs{
    margin-bottom: .375rem;
}
.mb\:sm, .my\:sm, .m\:sm{
    margin-bottom: .75rem;
}
.mb\:md, .my\:md, .m\:md{
    margin-bottom: 1rem;
}
.mb\:lg, .my\:lg, .m\:lg{
    margin-bottom: 1.25rem;
}

.mt\:xs, .my\:xs, .m\:xs{
    margin-top: .375rem;
}
.mt\:sm, .my\:sm, .m\:sm{
    margin-top: .75rem;
}
.mt\:md, .my\:md, .m\:md{
    margin-top: 1rem;
}
.mt\:lg, .my\:lg, .m\:lg{
    margin-top: 1.25rem;
}

.mr\:xs, .mx\:xs, .m\:xs{
    margin-right: .375rem;
}
.mr\:sm, .mx\:sm, .m\:sm{
    margin-right: .75rem;
}
.mr\:md, .mx\:md, .m\:md{
    margin-right: 1rem;
}
.mr\:lg, .mx\:lg, .m\:lg{
    margin-right: 1.25rem;
}

.ml\:xs, .mx\:xs, .m\:xs{
    margin-left: .375rem;
}
.ml\:sm, .mx\:sm, .m\:sm{
    margin-left: .75rem;
}
.ml\:md, .mx\:md, .m\:md{
    margin-left: 1rem;
}
.ml\:lg, .mx\:lg, .m\:lg{
    margin-left: 1.25rem;
}

.pb\:xs, .py\:xs, .p\:xs{
    padding-bottom: .375rem;
}
.pb\:sm, .py\:sm, .p\:sm{
    padding-bottom: .75rem;
}
.pb\:md, .py\:md, .p\:md{
    padding-bottom: 1rem;
}
.pb\:lg, .py\:lg, .p\:lg{
    padding-bottom: 1.25rem;
}

.pt\:xs, .py\:xs, .p\:xs{
    padding-top: .375rem;
}
.pt\:sm, .py\:sm, .p\:sm{
    padding-top: .75rem;
}
.pt\:md, .py\:md, .p\:md{
    padding-top: 1rem;
}
.pt\:lg, .py\:lg, .p\:lg{
    padding-top: 1.25rem;
}

.pl\:xs, .px\:xs, .p\:xs{
    padding-left: .375rem;
}
.pl\:sm, .px\:sm, .p\:sm{
    padding-left: .75rem;
}
.pl\:md, .px\:md, .p\:md{
    padding-left: 1rem;
}
.pl\:lg, .px\:lg, .p\:lg{
    padding-left: 1.25rem;
}

.pr\:xs, .px\:xs, .p\:xs{
    padding-right: .375rem;
}
.pr\:sm, .px\:sm, .p\:sm{
    padding-right: .75rem;
}
.pr\:md, .px\:md, .p\:md{
    padding-right: 1rem;
}
.pr\:lg, .px\:lg, .p\:lg{
    padding-right: 1.25rem;
}

.rt\:xs, .r\:xs{
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}
.rt\:sm, .r\:sm{
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
}
.rt\:md, .r\:md{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.rt\:lg, .r\:lg{
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}

.rb\:xs, .r\:xs{
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
}
.rb\:sm, .r\:sm{
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
}
.rb\:md, .r\:md{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.rb\:lg, .r\:lg{
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

.text\:center{
    vertical-align: center;
}
.text-align-left{
    text-align: left;
}
.text-align-center{
    text-align: center;
}


h1{
    font-size: 2.25rem;
    line-height: 3rem;
}
h2{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h3{
    font-size: 1.5rem;
    line-height: 2rem;
}
h4{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
h5{
    font-size: 1.125rem;
    line-height: 1.5rem;
}
h6{
    font-size: 1rem;
    line-height: 1.5rem;
}
p{
    font-size: .75rem;
}

.f\:xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.f\:sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.f\:md{
    font-size: 1rem;
    line-height: 1.5rem;
}
.f\:lg{
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.f\:xl{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.f\:2xl{
    font-size: 1.5rem;
    line-height: 2rem;
}
.f\:3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.f\:300{
    font-weight: 300;
}
.f\:400{
    font-weight: 400;
}
.f\:500{
    font-weight: 500;
}

.f\:600{
    font-weight: 600;
}
.f\:700{
    font-weight: 700;
}
.f\:bold{
    font-weight: bold;
}
.f\:grey{
    color: #555555;
}
.f\:black{
    color: rgb(30, 30, 30);
}
.f\:light-grey{
    color: #858585;
}
.f\:white{
    color: white;
}
.f\:orange{
    color: #ff504a;
}
.f\:icon {
    font-size: 1rem;
}
.bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.bg\:head-bar{
    background-color: rgba(255,255,255);
}
.bg\:overlay{
    background-color: rgba(0,0,0,.5);
}
.bg\:trans{
    background-color: transparent;
}
.bg\:black{
    background-color: black;
}
.bg\:grey{
    background-color: #eef2f5;
}
.bg\:white{
    background-color: white;
}
.bg\:pink{
    background-color: pink;
}
.bg\:green{
    background-color: green;
}
.bg\:yellow{
    background-color: #ffaa32;
}
.bg\:orange{
    background-color: #ff504a;
}

.min-h\:vh{
    min-height: 100vh;
}
.h\:full{
    height: 100%;
}
.h\:min{
    min-height: 100vh;
}
.h\:auto{
    height: auto;
}
.h\:inherit{
    height: inherit;
}
.w\:max{
    max-width: 768px;
}
.min-w\:vw{
    width: 100vw;
}
.w\:min{
    min-width: 100vw;
}
.w\:full{
    width: 100%;
}
.w\:auto{
    width: auto;
}
.w\:inherit{
    width: inherit;
}
.w\:4\/5{
    width: 80%;
}
.w\:3\/4{
    width: 75%;
}
.w\:1\/2{
    width: 50%;
}
.w\:1\/3{
    width: 66.666667%;
}
.w\:1\/4{
    width: 25%;
}
.max-w\:1\/2{
    max-width: 50%;
}
.max-w\:1\/4{
    max-width: 25%;
}
h1, h2, h3, h4, h5, h6, p{
    color: #555555;
    /* color: #2f2f2f; */
    font-weight: 500;
    margin: 0;
}

a{
    text-decoration: none;
}
a:link {
    color: transparent;
}
/* visited link */
a:visited {
    color: transparent;
}
/* mouse over link */
a:hover {
    color: transparent;
}
/* selected link */
a:active {
    color: transparent;
}