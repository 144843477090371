.app-checkout{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    bottom: 0;
    right: 0;
    /* float: right; */
    background-color: rgba(238,242,245);
    overflow-x: hidden;
    transition: 0.2s;
}

.checkout-head{
    position: fixed;
    z-index:4; 
}
.checkout-body{
  height: 90vh;
}
/* .checkout-info-box{
    min-height: 80px;
} */
.checkout-edit-btn{
    color: #c7cdd6;
    border-color: #c7cdd6;
    min-height: 40px;
    min-width: 40px;
}

label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }
  
  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 50px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    /* background-color: #6772e5; */
    background-color: #fa6d42;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }
  
  button:active {
    color: #fff;
    cursor: pointer;
    background-color: #ff8b67;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  button::-moz-selection,
  button::selection
  /* @media only screen and (min-width: 768px) {
    button:hover {
      color: #fff;
      cursor: pointer;
      background-color: #7795f8;
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
  } */
  
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  
  input::placeholder {
    color: #aab7c4;
  }
  
  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }