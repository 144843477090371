.app-addons{
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #eef2f5;
    /* background-color: yellow; */
    transition: 0.2s;
    overflow-y: scroll;
    
    /* -webkit-overflow-scrolling: auto */
  }
  .addons-head{
      position: fixed;
      z-index:3;    
  }
  
  .addons-head-btn{
      min-height: 50px;
      min-width: 50px;
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .web-addons-body{
    height: calc(100vh - 75px - 75px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .addons-img{
      display: block;
      height: 200px;
  }
  .sized-box{
      height: 70px;
  }
  
  .addons-choices-box{
      min-height: 40px;
  }
  
  .addons-choice:last-child{
      /* border: 1px solid rgba(0,0,0,.2); */
      border-bottom: 0px solid rgba(0,0,0,.2);
  }
  .addons-choice{
      border-bottom: 1px solid rgba(0,0,0,.2);
      vertical-align: middle;
      height: 40px;
  }
  
  .addons-special{
      min-height: 80px;
      border: 1px solid #ccc
  }
  .addons-bar{
      height: 50px;
      width: 90%;
      justify-content: space-between;
  }
  .addons-btn-gp{
      width: 30%;
      justify-content: space-evenly;
  }
  .addons-bar-btn{
      height: 40px;
      width: 40px;
      border-radius: 50px;
  }
  .addons-bar-btn:hover{
      background-color: rgb(167, 165, 165);
      color: black;
  }
  .addons-total{
      width: 40%;
  }
  .addons-add-btn{
      width: 30%;
  }
  /* .addons-submit-btn{
      width: 100px;
      height: 50px;
      background-color: transparent;
      box-shadow: unset;
      color: #ff504a;
      margin-top: unset;
  } */
  .addons-btn{
      height: 50px;
      width: 50px;
      background-color: transparent;
      box-shadow: unset;
      color: #ff504a;
      margin-top: unset !important;
      border-radius: 50%;
  }
  :root {
      --item-parent: #ffbbb8;
      --item-parent-font: #555555;
      --item-parent-standard-item: #a0a0a0;
      --item-parent-standard-item-font: white;
      --item-parent-selected: #ffebea;
      --item-parent-selecting: #ffbbb8;
      --item-parent-selecting-font: #555555;
      --item-parent-selected-font: #555555;
      --item-parent-unselect: #a0a0a0;
      --item-parent-unselect-font: white;
      --item-child: white;
      --option-parent: #a0a0a0;
      /* --option-parent: #ffcda3; */
      --option-parent-selected-font: #555555;
      --option-parent-selected: #ffddc1;
      --addons-submit-btn: #fd817c;
      --item-error: #ff6464;
      --item-parent-weight: 600;
      --item-parent-selected-weight: 600;
      --item-parent-selecting-weight: 600;
  
      --option-parent-height: 30px;
      --item-parent-height: 45px;
      --no-option: #ff927c;
      --addons-btn-margin: 0.4rem;
    }
  .addons-body button{
      margin-top: var(--addons-btn-margin);
  }
  .option-parent{
      padding-left: 0.6rem;
      height: 45px;
  }
  .item-error{
      border: var(--item-error) 3px solid;
  }
  .no-option-btn{
      width: 100%;
      height: 45px;
      /* height: var(--option-parent-height); */
      background-color: var(--no-option);
      /* background-color: grey; */
      font-weight: 600;
      font-size: 1.15rem;
      text-overflow: ellipsis;
      font-weight: 600;
      /* padding-left: 0.6rem; */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .no-option-btn h6{
      /* font-size: 1.15rem; */
      color: var(--item-parent-unselect-font);
      font-weight: var(--item-parent-weight);
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent{
      width: 100%;
      background-color: var(--item-parent);
      /* background-color: grey; */
      font-weight: 600;
      font-size: 1.15rem;
      text-overflow: ellipsis;
      font-weight: 600;
      padding: 0 0.6rem;
      text-align: unset;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent h5{
      font-size: 1.15rem;
      color: var(--item-parent-font);
      font-weight: var(--item-parent-weight);
      text-transform: none;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  
  .addons-item-parent-unselect{
      width: 100%;
      background-color: var(--item-parent-unselect);
      /* background-color: grey; */
      font-weight: 600;
      font-size: 1.15rem;
      text-overflow: ellipsis;
      font-weight: 600;
      padding-left: 0.6rem;
      text-align: unset;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent-unselect h5{
      font-size: 1.15rem;
      color: var(--item-parent-unselect-font);
      font-weight: var(--item-parent-weight);
      text-transform: none;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  
  .addons-item-parent-selected, .addons-item-parent-selecting{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: var(--item-parent-selected);
      padding: 0.6rem; 
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent-selected h5,
  .addons-item-parent-selecting h5{
      font-size: 1.15rem;
      font-weight: 600;
      text-transform: none;
      /* text-overflow: ellipsis; */
      /* overflow: hidden; */
  }
  .addons-item-parent-selected{
      /* text-overflow: ellipsis;
      overflow-x: hidden; */
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: var(--item-parent-selected);
  }
  .addons-item-parent-selecting{
      /* text-overflow: ellipsis;
      overflow-x: hidden; */
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: var(--item-parent-selecting);
  }
  .addons-item-parent-selected h5{
      color: var(--item-parent-selected-font);
  }
  .addons-item-parent-selecting h5{
      color: var(--item-parent-selecting-font);
  }
  .addons-item-parent-standard-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: var(--item-parent-standard-item);
      text-overflow: ellipsis;
      padding: 0 0.6rem; 
  }
  .addons-item-parent-standard-item h5{
      font-size: 1.15rem;
      font-weight: 600;
      color: var(--item-parent-standard-item-font);
  }
  
  .addons-item-parent-selected-standard-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: var(--item-parent-selected);
      text-overflow: ellipsis;
      padding: 0 0.6rem; 
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent-selected-standard-item h5{
      font-size: 1.15rem;
      color: var(--item-parent-selected-font);
  }
  .addons-option-parent{
      align-self: flex-end;
      width: 100%;
      background-color: var(--option-parent);
      font-weight: 600;
      font-size: 1.15rem;
      text-align: unset;
      text-overflow: ellipsis;
  }
  .addons-option-parent h5{
      font-size: 1.15rem;
      color: white;
      font-weight: var(--item-parent-weight);
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-option-parent-selected{
      align-self: flex-end;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      width: 100%;
      font-size: 1.15rem;
      background-color: var(--option-parent-selected);
      text-overflow: ellipsis;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-option-parent-selected h5{
      font-size: 1.15rem;
      font-weight: 600;
      color: var(--option-parent-selected-font);
  }
  
  .addons-item-parent-selected div{
      /* color: white; */
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
  }
  .selected-item-info{
      width: 80%;
      justify-content: center;
  }
  .selected-item-name{
      text-align: left;
      overflow-x: hidden;
      text-overflow: ellipsis;
  }
  .addons-item-parent:active{
      background-color: #fa5c56;
  }

  .standard-item{
    /* padding-left: 0.6rem;
    padding-right: 0.6rem; */
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    align-items: center;
    height: 50px;
    margin-top: var(--addons-btn-margin);
    padding: 0 0.6rem;
    /* margin-top: 10px;
    margin-bottom: 5px; */
  }
  .standard-item h5{
    font-size: 1.15rem;
    font-weight: 500;
  }
  
  .addones-item-child-grid{
      /* display: grid;
      grid-template-columns: 49% 49%; 
      grid-column-gap: 2%; */
      /* justify-self: center; */
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      /* flex: 2 1 auto; */
      /* row-gap: 2%; */
      /* column-gap: var(--addons-btn-margin); */
      justify-content: space-between;
      /* column-gap: 0.3rem; */
  }
  .addones-option-child-grid{
      align-self: flex-end;
      width: 100%;
      /* display: grid; 
      grid-template-columns: 49% 49%;
      grid-column-gap: 0.3rem; */
      display: flex;
      flex-direction: row;
      /* flex-flow: column wrap; */
      flex-wrap: wrap;
      justify-content: space-between;
  }
  .addons-item-child, .addons-item-child-selected{
      /* border: #fd817c 1px solid; */
      /* white-space: nowrap; */
      /* text-overflow: ellipsis; */
      background-color: var(--item-child);
      color: black;
      font-weight: 400;
      font-size: 0.75rem;
      text-align: center;
      padding: 0.6rem;
      justify-content: space-between;
      flex-basis: 48%;
      /* flex-grow: 2; */
      /* flex-grow: 100%; */
      max-width: 100%;
      text-transform: none;
  }
  .addons-item-child.small-box, .addons-item-child-selected.small-box{
    flex-basis: auto;
    margin-right: 5px;
  }
  .addons-item-child-selected{
      border: var(--item-parent-selecting) 3px solid;
  }
  
  
  .addons-item-child-name {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      /* word-break: break-all; */
      /* width: 90vw; */
  }
  .addons-option-child, .addons-option-child-selected{
      /* border: #fd817c 1px solid; */
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: var(--item-child);
      overflow: hidden;
      color: black;
      font-weight: 400;
      font-size: 0.75rem;
      text-align: center;
      padding: 0.6rem; 
      flex-basis: 48%;
      /* flex-grow: 2; */
      max-width: 100%;
  }
  
  
  .addons-option-child-selected{
      border: var(--option-parent-selected) 3px solid;
  }
  
  .addons-option-child-name{
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
  }
  .addons-sized-box{
      height: 90px;
  }
  .addons-submit-btn-wrap{
      position: fixed;
      z-index: 10;
      border-radius: unset;
      background-color: var(--addons-submit-btn);
      /* background-color: yellow; */
      font-size: 1.15rem;
      overflow: hidden;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 70px;
      transition: 0.2s;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer; 
  }
  .addons-submit-btn-wrap:active {
    color: #fff;
    cursor: pointer;
    background-color: #ff8b67;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  .addons-submit-btn{
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: unset;
      background-color: var(--addons-submit-btn);
      font-size: 1.15rem;
      margin-top: 0!important;
  }
  .addons-submit-btn h4{
      color: white;
      font-weight: 600;
  }
  .addons-price{
      position: absolute;
      right: 0;
      top: 25px;
      padding-right: 10px;
  }
  /* .addons-submit-btn-bg{
      z-index: 1;
      overflow: hidden;
      position: fixed;
      height: 50px;
      right: 0;
      bottom: 0;
      background-color: var(--addons-submit-btn);
      transition: 0.2s;
  } */

.trash-wrapper{
    display: flex;
    justify-content: flex-end;
}
.trash-wrapper.child-option{
    width: 100%;
    align-self: flex-end;
}
.trash{
    background-color: #fa3b31;
    color: white;
    display: flex;
    align-items: center;
    box-shadow: none;
}
.trash.child-option{
    height: 45px;
}
.addons-option-parent-selected div:nth-child(2){
    margin: 0 auto;
}
.option-button-wrapper{
    display: flex;
    justify-content: flex-end;
}
.option-stack-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 90%;
    margin-left: auto;
}
.option-stack-wrapper > div{
    width: 100%;
}
.no-option-btn-wrap{
    margin-right: 5px;
}
.addones-item-child-grid.small-grid-container{
    justify-content: flex-start;
}