/* .app-order-review{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 4;
    overflow-x: hidden;
    transition: 0.2s;
    background-color: rgba(0,0,0, 0.5);
    backdrop-filter: blur(8px);
} */
.app-order-review{
    height: 100%;
    width: 0%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 4;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: 0.2s;
    background-color: rgba(0,0,0, 0.5);
    backdrop-filter: blur(8px);
}

.order-review-wrap{
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #eef2f5;
    border-radius: .75rem;
}

.web-order-review-wrap{
    height: calc(100vh - 75px - 145px);
    /* position: relative; */
}
/* .review-detail{
    overflow-y: scroll;
} */
.order-review-body{

}

.order-review-head .close-btn{
    border-radius: 50%;
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
}
.order-review-head{
    min-height: 50px;
    max-height: 70px;
    position: fixed;
    z-index: 4;
}
.order-review-overview{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: #eef2f5;
    transition: 0.2s;
    height: 130px;
    width: 100%;
    border-top: 1px solid rgb(190, 190, 190);
}
.price-overview{
    display: grid;
    grid-template-columns: 30% 1fr auto;
    column-gap: 10px;
    row-gap: 3px;
    /* padding: 10px; */
}
/* .price-overview h5{
    height: 30px;
} */
.price-overview .title{
    text-align: right;
}